import React, { useState } from 'react'
import { Button,Badge, Form,Navbar,Container } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { useNavigate  } from 'react-router-dom';
import Typed from 'react-typed';

function SearchBox() {
    const [keyword, setKeyword] = useState('')

    const navigate = useNavigate();
    const history = useLocation();

    const submitHandler = (e) => {
        console.log("aneees");
        e.preventDefault()
        if (keyword) {  
            console.log("anees")
            navigate(`/projects?keyword=${keyword}`)
            
        } else {
            navigate('/projects')
        }
    }
    
    return (
        <Navbar bg="light" expand="lg">
        <Container fluid>
        <h2><Badge bg="success p-2">Hey Folks </Badge>   <Typed
                    strings={["100% Authentic Project","Free Source Code","Installation Support","Real time Projects"]}
                    typeSpeed={50} backSpeed={10} loop 
                /></h2>
        <Form className="d-flex" onSubmit={submitHandler} inline>
            <Form.Control
                type='text'
                placeholder='Search Project'
                name='q'
                onChange={(e) => setKeyword(e.target.value)}
                className='me-2'
            ></Form.Control>

            <Button
                type='submit'
                variant='outline-dark'
                className='p-2'
            >
                Submit
            </Button>
        </Form>
        </Container>
        </Navbar>
    )
}

export default SearchBox
