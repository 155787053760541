import React, { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Image } from "react-bootstrap";
import Loader from "./Loader";
import Message from "./Message";
import { listprofile } from "../actions/contactAction";
import { Row, Col } from "react-bootstrap";
import Typed from 'react-typed';


function Corousell() {
  
  const dispatch = useDispatch();
  const [skilldata,setSkilldata]=useState('')
  const profileList = useSelector((state) => state.profileList);
  const { error, loading, profiles } = profileList;
  const profiledata = () => {
      profiles.map((profile)=>(
        setSkilldata(profile.skill)      
      ))
  }
// console.log(skilldata)
  const mydataskill =skilldata.split(",")
  useEffect(() => {
    profiledata();
    dispatch(listprofile());
    
  }, [dispatch]);
  
  return loading ? (
    
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <div>
      {profiles.map((profile) => (
        <Row id="blueberryrow">
          <Col md={9} className="blueberry">
            <Container id="contain">
          
              <h1 className="text-black mt-5" id="display-h1">Welcome To:  <span className="btn btn-outline-dark" id="spandata"><Typed
                    strings={[`${profile.name}`]}
                    typeSpeed={50} backSpeed={10} loop 
                /></span></h1>
              <Image className="container-image" id="mobile" src={profile.image} alt={profile.name}  />
              
              <h4 className="text-dark" id="display-h4">Expertise Skills : <span className="text-success" id="spandata"><Typed
                    strings={mydataskill}
              
                    typeSpeed={50} backSpeed={10} loop 
                /></span> </h4>

              <p>
                {profile.about}
              </p>
             <Row className="btn-display">
      <Col md={10}><Link to="/enroll" className="btn btn-success" type="button">Enroll for Workshop</Link>
      <Link to="/projects" className="btn btn-dark m-3" type="button">Projects</Link>
      </Col>
      
    </Row> 
            </Container>
          </Col>
          <Col md={3}>
            <Image className="container-image" id="desktop" src={profile.image} alt={profile.name}  />
          </Col>
        </Row>
      ))}

    
    </div>
  );
}

export default Corousell;
