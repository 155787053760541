import React, { useEffect } from 'react'
import { Row, Col } from "react-bootstrap";
import { Container, } from 'react-bootstrap'
import Techs from '../Techs';
import Loader from '../Loader';
import Message from '../Message';
import { listTechnologys } from '../../actions/techAction';
import { useDispatch, useSelector } from "react-redux";
import { listServices } from '../../actions/servicesAction';
import Services from '../Services';
import Corousell from '../Corousell';
import Typed from 'react-typed';
function HomeScreen() {


 
  const dispatch = useDispatch();
  const technologysList = useSelector((state) => state.technologysList);
  const { error, loading, techs } = technologysList;

  const servicesList = useSelector((state) => state.servicesList);
  const { errorser, loadingser, services } = servicesList;


  useEffect(() => {
    
    dispatch(listTechnologys());
    dispatch(listServices());
 

  }, [dispatch]);
  return (
<Container>

  <Corousell />

<div>
      <h1 className="text-center mt-3"><Typed
                    strings={["Technologies Expert"]}
                    typeSpeed={50} backSpeed={10}  
                /></h1>
      {loading ? (
       <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Row>
          {techs.map((tech) => (
            <Col key={tech._id} sm={12} md={6} lg={4} xl={3}>
       
              <Techs tech={tech} />
            </Col>
          ))}
        </Row>
      )}
<hr />

<h1 className="text-center mt-3"><Typed
                    strings={["Services"]}
                    typeSpeed={50} backSpeed={10}  loop
                /></h1>
{loadingser ? (
       <Loader />
      ) : errorser ? (
        <Message variant='danger'>{errorser}</Message>
      ) : (
        <Row>
          {services.map((service) => (
            <Col key={service._id} sm={12} md={6} lg={4} xl={3}>
            
              <Services service={service} />
            </Col>
          ))}
        </Row>
      )}



    </div>
  </Container>
  )
}

export default HomeScreen